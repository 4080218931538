import React from 'react'
import { ServiceCard } from '../components/serviceCard'
import { StaticQuery, graphql } from 'gatsby'
import './ourServices.css'

export const servicesFluidImg = graphql`
  fragment servicesFluidImg on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
const OurServices = () => (
  <StaticQuery
    query={graphql`
      query {
        interiorDesign: file(relativePath: { eq: "services/1.png" }) {
          ...servicesFluidImg
        }
        qualityControl: file(relativePath: { eq: "services/2.png" }) {
          ...servicesFluidImg
        }
        marketingSolutions: file(relativePath: { eq: "services/3.png" }) {
          ...servicesFluidImg
        }
      }
    `}
    render={data => (
      <div id="services">
        <h1 className="our-services-text">-OUR SERVICES-</h1>
        <div className="our-services-card">
          <ServiceCard
            image={data.interiorDesign}
            caption="interior design services from duato"
            to="interiorDesign"
            bigCaption="interior design"
          />
          <ServiceCard
            image={data.qualityControl}
            caption="quality control services duato"
            to="qualityControl"
            bigCaption="quality control"
          />
          <ServiceCard
            image={data.marketingSolutions}
            caption="marketing solutions"
            to="marketingSolutions"
            bigCaption="marketing solutions"
          />
        </div>
      </div>
    )}
  />
)
export { OurServices }
