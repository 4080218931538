import React from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'
// import './mainSlider.css'
import { StaticQuery, graphql } from 'gatsby'
const settings = {
  autoplay: true,
  dots: true,
  arrows:false,
  adaptiveHeight:true,
}
const MainSlider = () => (
  <StaticQuery
    render={data => (
      <React.Fragment>
        <Slider {...settings}>
          <Img fluid={data.firstSlide.childImageSharp.fluid} />
          <Img fluid={data.secondSlide.childImageSharp.fluid} />
          <Img fluid={data.thirdSlide.childImageSharp.fluid} />
        </Slider>
      </React.Fragment>
    )}
    query={graphql`
      query {
        firstSlide: file(relativePath: { eq: "slider/head-pic01.jpg" }) {
          ...fluidImage
        }
        secondSlide: file(relativePath: { eq: "slider/head-pic02.jpg" }) {
          ...fluidImage
        }
        thirdSlide: file(relativePath: { eq: "slider/head-pic03.jpg" }) {
          ...fluidImage
        }
      }
    `}
  />
)
export default MainSlider
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
