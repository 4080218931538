import React from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import MainSlider from '../components/mainSlider'
import { OurServices } from '../components/ourServices'
import LikeFamily from '../components/likeFamily'
import Layout from '../components/layout'
import { TimeLine } from '../components/timeLine'
import { DesignOurProjects } from '../components/designOurProjects'
import Portfolio from '../components/portfolio'
import Partners from '../components/partners'
const IndexPage = () => (
  <Layout>
    <MainSlider />
    <OurServices />
    <LikeFamily />
    <TimeLine />
    <DesignOurProjects />
    <Portfolio />
    <Partners />
  </Layout>
)

export default IndexPage
