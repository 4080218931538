import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'
// import Img from 'gatsby-image'
import './portfolio.css'
import pic01 from '../images/portfolio/001.jpg'
import pic02 from '../images/portfolio/002.jpg'
import pic03 from '../images/portfolio/003.jpg'
import pic04 from '../images/portfolio/004.jpg'
import pic05 from '../images/portfolio/005.jpg'
import pic06 from '../images/portfolio/006.jpg'
import pic07 from '../images/portfolio/007.jpg'
import pic08 from '../images/portfolio/008.jpg'
import pic09 from '../images/portfolio/009.jpg'
import pic10 from '../images/portfolio/010.jpg'
import pic11 from '../images/portfolio/011.jpg'
import pic12 from '../images/portfolio/012.jpg'

const photos = [
  {
    src: pic01,
    width: 4,
    height: 3,
  },
  { src: pic02, width: 4, height: 3 },
  { src: pic03, width: 4, height: 3 },
  { src: pic04, width: 4, height: 3 },
  { src: pic05, width: 4, height: 3 },
  { src: pic06, width: 4, height: 3 },
  { src: pic07, width: 4, height: 3 },
  { src: pic08, width: 4, height: 3 },
  { src: pic09, width: 4, height: 3 },
  { src: pic10, width: 4, height: 3 },
  { src: pic11, width: 4, height: 3 },
  { src: pic12, width: 4, height: 3 },
]
class Portfolio extends Component {
  constructor() {
    super()
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
    // this.photos = [{src:this.props.images.firstSlide, width:4, height:3}]
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  render() {
    return (
      <div>
        <Gallery
          photos={photos}
          onClick={this.openLightbox}
          margin={4}
          columns={3}
          // ImageComponent={Img}
        />
        <Lightbox
          images={photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    )
  }
}
export default Portfolio
