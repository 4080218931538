import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import { StaticQuery, graphql } from 'gatsby'
import './partners.css'
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  dots: false,
}
const Partners = () => (
  <StaticQuery
    render={data => (
      <div>
        <div className="img-partner">
          <Img fluid={data.partnerTitle.childImageSharp.fluid} />
          <div className="partner-text__container">
            <h1 className="partners-text"> PARTNERS BY BRAND</h1>
            <p className="thick__line" />
            <h3 className="partners-text">We have a variety of partners</h3>
          </div>
        </div>

        <div className="partners-container">
          <Slider {...settings}>
            <div>
              <Img fluid={data.arkspa.childImageSharp.fluid} />
            </div>

            <div>
              <Img fluid={data.aulos.childImageSharp.fluid} />
            </div>

            <div>
              <Img fluid={data.bellavitta.childImageSharp.fluid} />
            </div>

            <div>
              <Img fluid={data.borto.childImageSharp.fluid} />
            </div>

            <div>
              <Img fluid={data.castel.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.ilucidi.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.viewgress.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.vwin.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.zimmermanswarch.childImageSharp.fluid} />
            </div>
          </Slider>
        </div>
      </div>
    )}
    query={graphql`
      query {
        partnerTitle: file(relativePath: { eq: "partner-title.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        arkspa: file(relativePath: { eq: "partners/arkspa.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aulos: file(relativePath: { eq: "partners/aulos.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bellavitta: file(relativePath: { eq: "partners/bellavitta.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        borto: file(relativePath: { eq: "partners/borto.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        castel: file(relativePath: { eq: "partners/castel.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ilucidi: file(relativePath: { eq: "partners/ilucidi.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        viewgress: file(relativePath: { eq: "partners/viewgress.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        vwin: file(relativePath: { eq: "partners/vwin.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        zimmermanswarch: file(
          relativePath: { eq: "partners/zimmermanswarch.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
  />
)
export default Partners
