import React from 'react'
import Img from 'gatsby-image'
import './likeFamily.css'
import { StaticQuery, graphql } from 'gatsby'
const LikeFamily = () => (
  <StaticQuery
    query={graphql`
      query {
        likeFamily: file(relativePath: { eq: "likefamily.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="like-family-div">
        <Img fluid={data.likeFamily.childImageSharp.fluid} />
        <div className="like-family-text__container">
          <h3 className="like-family-h2">LIKE A FAMILY</h3>
          <p className="thick__line" />
          <h3 className="like-family-h2"> We are a aprogressive team</h3>
        </div>
      </div>
    )}
  />
)
export default LikeFamily
