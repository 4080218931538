import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

const TimeLine = () => (
  <StaticQuery
    render={data => (
      <div id="timeline">
        <h1 style={{ textAlign: `center` }}>Timeline</h1>
        <Img fluid={data.timeline.childImageSharp.fluid} />
      </div>
    )}
    query={graphql`
      query {
        timeline: file(relativePath: { eq: "timeline/duato-timeline.png" }) {
          ...fluidImage
        }
      }
    `}
  />
)
export { TimeLine }
