import React from 'react'
import Img from 'gatsby-image'
import './designOurProjects.css'
import { StaticQuery, graphql } from 'gatsby'
const DesignOurProjects = () => (
  <StaticQuery
    query={graphql`
      query {
        designOurProjects: file(
          relativePath: { eq: "interior-design-title.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="design-our-projects-div">
        <Img fluid={data.designOurProjects.childImageSharp.fluid} />
        <div className='design-our-projects-text__container'>
          <h2 className='design-our-projects-text'>DESING OUR PROJECTS</h2>
          <p className='thick__line'></p>
          <h3 className='design-our-projects-text'>
            We offer a high quality design for our customers
          </h3>
        </div>
      </div>
    )}
  />
)
export { DesignOurProjects }
