import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './serviceCard.css'
const ServiceCard = props => (
  <Link className="service-card-style" to={props.to}>
    <Img
      fluid={props.image.childImageSharp.fluid}
      className='service-card-img'
    />
    <div >
      <h2 className='service-card-h2'>{props.bigCaption}</h2>
      <h4 className="service-card-h4">{props.caption}</h4>
    </div>
  </Link>
)

export { ServiceCard }
